import axios from 'axios';
import { useState } from 'react';
import logo from '../images/logo.png';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', {
        username,
        password,
      });
      if (response.data.success) {
        setIsAuthenticated(true);
      } else {
        setErrorMessage("Nom d'utilisateur ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="loginPanelBackground">
      <div className="loginPanel">
        <div className="loginLogoContainer">
          <img className="loginLogo" src={logo} alt="Logo" />
        </div>
        <form onSubmit={login}>
          <label>Nom d'utilisateur</label>
          <input
            type="text"
            name="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrorMessage('');
            }}
            required
          />
          <label>Mot de passe</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
            required
          />
          <button className="button greenButton fullWidth" type="submit" disabled={!username || !password}>
            Se connecter
          </button>
          {errorMessage && <span>{errorMessage}</span>}
        </form>
      </div>
    </div>
  );
};

export default Login;
